@import "https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300;600;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  *, :before, :after {
    -o-transition-duration: .01ms !important;
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

:root {
  --blue: #4f7df3;
  --red: #da2c25;
  --med-blue: #84a3f0;
  --med-red: #e95c57;
  --pale-blue: #c2d3ff;
  --light-red: #f26c7a;
  --gray: #969696;
  --very-dark-blue: #151f29;
  --white-static: white;
  --white: white;
  --success: #007e33;
  --contrast: 1;
}

::selection {
  background-color: var(--red);
  color: var(--white-static);
}

@media only screen and (min-width: 37.5em) {
  html {
    font-size: 120%;
  }
}

@media only screen and (min-width: 61.25em) {
  html {
    font-size: 140%;
  }
}

body {
  color: var(--very-dark-blue);
  background-color: var(--white);
  min-height: 100vh;
  flex-direction: column;
  font-family: Libre Franklin, sans-serif;
  display: flex;
}

.main-contents {
  max-width: 35rem;
  margin: 1.5rem auto 0;
  padding: 1.5rem;
  position: relative;
}

@media only screen and (min-width: 61.25em) {
  .main-contents {
    margin-top: 1rem;
  }
}

.main-contents .logo {
  height: 4rem;
  width: auto;
  filter: contrast(var(--contrast));
  margin-bottom: 1rem;
}

@media only screen and (min-width: 61.25em) {
  .main-contents .logo {
    height: 4rem;
    margin-bottom: .2rem;
  }
}

.main-contents .logo img {
  width: 100%;
  height: 100%;
}

.main-contents__heading {
  color: var(--gray);
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 300;
}

@media only screen and (min-width: 61.25em) {
  .main-contents__heading {
    font-size: 2rem;
  }
}

.main-contents__heading span {
  color: var(--very-dark-blue);
  font-weight: 600;
}

.main-contents__description {
  margin-bottom: 1rem;
  font-size: .9rem;
}

.main-contents .top-contents {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  padding: 1.3rem;
  display: flex;
}

@media only screen and (min-width: 61.25em) {
  .main-contents .top-contents {
    margin-bottom: 2rem;
  }
}

.main-contents .form-container {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  gap: 1rem;
  display: flex;
}

.main-contents .form-container .input-email, .main-contents .form-container .btn-submit {
  background-color: var(--white);
  border-radius: 99999px;
  padding: .5rem 1rem;
}

.main-contents .form-container .input-email:focus, .main-contents .form-container .btn-submit:focus {
  outline-offset: 2px;
  outline-width: 3px;
  outline-color: var(--gray);
  outline-style: dotted;
}

.main-contents .form-container .input-email {
  border: 1px solid var(--very-dark-blue);
  width: 100%;
  color: var(--very-dark-blue);
  padding: .5rem 1.5rem;
  overflow: hidden;
}

.main-contents .form-container .input-email::placeholder {
  color: var(--gray);
}

.main-contents .form-container .input-wrapper {
  text-align: center;
  width: 100%;
}

@media only screen and (min-width: 37.5em) {
  .main-contents .form-container .input-wrapper {
    text-align: left;
  }
}

.main-contents .form-container .input-wrapper .output-message {
  padding: 0 1.5rem;
  font-size: .8rem;
  font-style: italic;
}

.main-contents .form-container .btn-submit {
  background-color: var(--red);
  color: var(--white-static);
  cursor: pointer;
  width: 100%;
  border: none;
  font-size: .9rem;
  font-weight: 600;
  transition: background-color .2s;
  overflow: hidden;
  box-shadow: 0 5px 5px #4f7df366;
}

.main-contents .form-container .btn-submit:hover {
  background-color: var(--med-red);
}

.main-contents .form-container .btn-submit:active {
  background-color: var(--red);
}

@media only screen and (min-width: 37.5em) {
  .main-contents .form-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .main-contents .form-container .btn-submit {
    width: 40%;
  }
}

.footer {
  margin-top: auto;
}

.footer .social-links {
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.footer .social-links .social-link {
  height: 2rem;
  width: 2rem;
  border: 1px solid var(--red);
  cursor: pointer;
  border-radius: 99999px;
  justify-content: center;
  align-items: center;
  padding: .4rem;
  transition: all .2s;
  display: flex;
}

.footer .social-links .social-link svg {
  fill: var(--red);
  height: 100%;
  width: 100%;
}

.footer .social-links .social-link:hover {
  background-color: var(--red);
}

.footer .social-links .social-link:hover svg {
  fill: var(--white);
}

.footer .copyright {
  text-align: center;
  color: var(--gray);
  margin-bottom: 2rem;
  font-size: .7rem;
}

:root.dark-mode {
  --blue: #2e4fa3;
  --gray: #fcfcfc;
  --very-dark-blue: #5991c5;
  --white: #131316;
  --success: #00d856;
  --contrast: 10%;
}

.dark-mode-container {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.dark-mode-container .dark-mode-checkbox {
  display: none;
}

.dark-mode-container .dark-mode-lebel {
  --sh-height: 1.7rem;
  --border: calc(var(--sh-height) / 15);
  width: calc(var(--sh-height) * 2);
  height: var(--sh-height);
  border: var(--border) solid #38447a;
  cursor: pointer;
  background-color: #090831;
  border-radius: 99999px;
  justify-content: space-between;
  align-items: center;
  padding: 0 1px;
  display: flex;
  position: relative;
  box-shadow: 0 0 10px #181b304d;
}

.dark-mode-container .dark-mode-lebel > * {
  -webkit-user-select: none;
  user-select: none;
}

.dark-mode-container .dark-mode-lebel > img {
  height: calc(var(--sh-height)  - .5rem);
  width: calc(var(--sh-height)  - .5rem);
}

.dark-mode-container .dark-mode-lebel:before {
  content: "";
  width: var(--sh-height);
  height: calc(var(--sh-height)  - var(--border)  - var(--border));
  border: var(--border) solid #6770ee;
  background-color: #d4dbfd;
  border-radius: 99999px;
  transition: left .2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
}

.dark-mode-container .dark-mode-checkbox:checked ~ .dark-mode-lebel:before {
  left: calc(100% - var(--sh-height));
}

.attribution {
  --fs-attb: 13px;
  --clr-attb-primary: var(--red);
  --clr-attb-secondary: var(--light-red);
  color: var(--clr-Black);
  text-align: center;
  line-height: 2;
  font-size: var(--fs-attb);
  border-left: 2px solid var(--clr-attb-primary);
  border-right: 2px solid var(--clr-attb-primary);
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 10px;
  margin: 16px auto;
  padding: .3rem .5rem;
}

.attribution .attb-link {
  color: var(--clr-attb-primary);
  -o-transition: all .2s ease;
  -webkit-transition: all .2s ease-in-out;
  border-bottom: 2px dotted var(--clr-attb-primary);
  font-size: calc(var(--fs-attb)  + 4px);
  padding: 0 5px;
  text-decoration: none;
  transition: all .2s;
}

.attribution .attb-link:hover, .attribution .attb-link:focus {
  color: var(--clr-attb-secondary);
}

.attribution .attb-fem {
  font-size: calc(var(--fs-attb)  - 2px);
}

/*# sourceMappingURL=index.20e92b67.css.map */
